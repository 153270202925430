import bjCoverF from '../images/covers/bfp3_f.png';
import btbCoverF from '../images/covers/btb.jpg';
import cosCoverF from '../images/covers/CoSfront.png';
import ctrCoverF from '../images/covers/ctr_f.png';
import hgCoverF from '../images/covers/hgFront.png';
import howCoverF from '../images/covers/HoW cover.jpg';
import itrCoverF from '../images/covers/itr2_f.png';
import osCoverF from '../images/covers/os_f.png';
import otoCoverF from '../images/covers/otoFront.png';
import pifCoverF from '../images/covers/pif.jpg';

// CoS
import bdBFPDW2 from '../images/boards/bfpDW2.png';
import bdBFPDW3 from '../images/boards/bfpDW3.png';
import bdBFPDW4 from '../images/boards/bfpDW4.png';
import bdBFPL from '../images/boards/bfpL.png';
import bdBFPM from '../images/boards/bfpM.png';
import bdBFPN from '../images/boards/bfpN.png';

// PiF
import bdBFPDW5 from '../images/boards/bdBFPDW5.gif';
import bdBFPDW6 from '../images/boards/bdBFPDW6.gif';
import bdBFPO from '../images/boards/bdBFPO.gif';
import bdBFPP from '../images/boards/bdBFPP.gif';
import bdBFPQ from '../images/boards/bdBFPQ.gif';
import bdBFPR from '../images/boards/bdBFPR.gif';

// itr
import bdBFPA from '../images/boards/miniBFPA.gif';
import bdBFPB from '../images/boards/miniBFPB.gif';
import bdBFPDW7 from '../images/boards/miniBFPDW7.jpg';

const products = [
  {
    id: 'ITR2',
    name: 'Into the Rubble',
    blurb: 'Into The Rubble offers is all about cardboard battle in cities and railroad yards. The scenarios range from 1938 to 1945 with a variety of forces including American, Chinese, German/SS, Hungarian, Japanese, Nationalist Spanish, Republican Spanish, Romanian and Russian.',
    coverF: itrCoverF,
    coverB: '',
    available: true,
    version: '2',
    price1: 85,
    price2: 109,
    boards: [
      { src: bdBFPDW7, price: 7, name: 'DW-7' },
      { src: bdBFPA, price: 5, name: 'A' },
      { src: bdBFPB, price: 5, name: 'B' },
    ],
    vaslBoards: [
      { href: '/download/bdBFPDW7a.zip', name: 'DW-7a' },
      { href: '/download/bdBFPDW7b.zip', name: 'DW-7b' },
      { href: '/download/bdBFPA.zip', name: 'A' },
      { href: '/download/bdBFPB.zip', name: 'B v2' },
    ],
    counterBlurb: '',
    counters: [],
  },
  {
    id: 'CoS',
    name: 'Crucible of Steel',
    blurb: 'Crucible of Steel is a huge package that will thrust players into the battles of Kursk, which involved as many as 6,000 tanks and 2 million fighting men. This is meat and potatoes ASL, and with 32 Action-Packed scenarios there is plenty of action in Crucible of Steel for everybody!',
    coverF: cosCoverF,
    coverB: '',
    available: true,
    version: '2',
    price1: 139,
    price2: 165,
    boards: [
      { src: bdBFPDW2, price: 7, name: 'DW-2' },
      { src: bdBFPDW3, price: 7, name: 'DW-3' },
      { src: bdBFPDW4, price: 7, name: 'DW-4' },
      { src: bdBFPL, price: 5, name: 'L' },
      { src: bdBFPM, price: 5, name: 'M' },
      { src: bdBFPN, price: 5, name: 'N' },
    ],
    vaslBoards: [
      { href: '/download/bdBFPDW2a.zip', name: 'DW-2a' },
      { href: '/download/bdBFPDW2b.zip', name: 'DW-2b' },
      { href: '/download/bdBFPDW3a.zip', name: 'DW-3a' },
      { href: '/download/bdBFPDW3b.zip', name: 'DW-3b' },
      { href: '/download/bdBFPDW4a.zip', name: 'DW-4a' },
      { href: '/download/bdBFPDW4b.zip', name: 'DW-4b' },
      { href: '/download/bdBFPL.zip', name: 'L' },
      { href: '/download/bdBFPM.zip', name: 'M' },
      { href: '/download/bdBFPN.zip', name: 'N' },
    ],
    counterBlurb: '2 full sheets of counters, depicting more units, weapons, vehicles, and planes that are depicted in the scenarios.',
    counters: [
      { price: 40, name: 'US' },
      { price: 60, name: 'Canada' },
      { price: 75, name: 'International' },
    ],
  },
  {
    id: 'PiF',
    name: 'Poland in Flames',
    blurb: 'Poland in Flames covers the conflict that triggered the start of World War 2 in Europe when Germany invaded Poland in September, 1939. The 45 scenarios feature battles all across Poland, with the Poles taking on Germans, Russians, or Slovaks.',
    coverF: pifCoverF,
    coverB: '',
    available: true,
    version: '',
    price1: 139,
    price2: 165,
    boards: [
      { src: bdBFPDW5, price: 7, name: 'DW-5' },
      { src: bdBFPDW6, price: 7, name: 'DW-6' },
      { src: bdBFPO, price: 5, name: 'O' },
      { src: bdBFPP, price: 5, name: 'P' },
      { src: bdBFPQ, price: 5, name: 'Q' },
      { src: bdBFPR, price: 5, name: 'R' },
    ],
    vaslBoards: [
      { href: '/download/bdBFPDW4a.zip', name: 'DW-4a' },
      { href: '/download/bdBFPDW4b.zip', name: 'DW-4b' },
      { href: '/download/bdBFPL.zip', name: 'L' },
    ],
    counterBlurb: '840 Full color 1/2" counters, 440 Full color 5/8" counters',
    counters: [
      { price: 50, name: 'US' },
      { price: 65, name: 'Canada' },
      { price: 80, name: 'International' },
    ],
  },
  {
    id: 'OS',
    name: 'Objective: Schmidt',
    blurb: 'Objective Schmidt depicts the hard fought battles that occurred in early November, 1944 in the Hurtgen Forest by the U.S. 28th Infantry Division for the small towns of Schmidt/Kommerscheidt and Vossenack.',
    coverF: osCoverF,
    coverB: '',
    available: true,
    version: '',
    price1: 110,
    price2: 142,
    boards: [],
    vaslBoards: [
      { href: '/download/bdSch.zip', name: 'Schmidt Map' },
      { href: '/download/bdVoss.zip', name: 'Vossenack Map' },
    ],
    counterBlurb: '560 Full color 1/2" counters, 88 Full color 5/8" counters',
    counters: [
      { price: 30, name: 'US' },
      { price: 45, name: 'Canada' },
      { price: 60, name: 'International' },
    ],
  },
  {
    id: 'CtR',
    name: 'Corregidor: The Rock',
    blurb: 'Corregidor: the Rock takes a detailed look at the hard-fought battles for the island of Corregidor, located at the entrance to Manila Bay in the Philippines, during both the Japanese invasion in 1942 and the subsequent U.S. retaking of the island in 1945.',
    coverF: ctrCoverF,
    coverB: '',
    available: true,
    version: '',
    price1: 87,
    price2: 122,
    boards: [],
    vaslBoards: [
      { href: '/download/bdCtR.zip', name: 'Corregidor Map' },
    ],
    counterBlurb: '',
    counters: [
      { price: 30, name: 'US' },
      { price: 45, name: 'Canada' },
      { price: 60, name: 'International' },
    ],
  },
  {
    id: 'OtO',
    name: 'Onslaugh to Orsha',
    blurb: 'Onslaught to Orsha is a historical study about Operation Bagration, covering the action, termed The Death of Army Group Center, June - July 1944.',
    coverF: otoCoverF,
    coverB: '',
    available: true,
    version: '2',
    price1: 124,
    price2: 154,
    boards: [],
    vaslBoards: [
    ],
    counterBlurb: '',
    counters: [
      { price: 40, name: 'US' },
      { price: 50, name: 'Canada' },
      { price: 75, name: 'International' },
    ],
  },
  {
    id: 'B&J',
    name: 'Blood and Jungle',
    blurb: 'Blood and Jungle sends you into the Pacific Theater of Operations, fighting through jungles, swamps, kunai and other treacherous terrain while playing the 47 scenarios. For all of you who have been longing for some great PTO ASL action, Blood and Jungle was made for you!',
    coverF: bjCoverF,
    coverB: '',
    available: true,
    version: '2',
    price1: 139,
    price2: 165,
    boards: [],
    vaslBoards: [
      { href: '/download/bdBFPDW1a.zip', name: 'DW-1a' },
      { href: '/download/bdBFPDW1b.zip', name: 'DW-1b' },
      { href: '/download/bdBFPG', name: 'G' },
    ],
    counterBlurb: '',
    counters: [
      { price: 60, name: 'US' },
      { price: 75, name: 'Canada' },
      { price: 90, name: 'International' },
    ],
  },
  {
    id: 'HG2',
    name: 'High Ground',
    blurb: 'High Ground 2 has 2 unique map boards that make up a big hill, 2 other challenging map boards depicting mountains with village terrain, a specialized half-board castle overlay, and 16 scenarios.',
    coverF: hgCoverF,
    coverB: '',
    available: false,
    version: '2',
    price1: 0,
    price2: 0,
    boards: [],
    vaslBoards: [
      { href: '/download/bdBFPH', name: 'H' },
      { href: '/download/bdBFPI', name: 'I' },
      { href: '/download/bdBFPJ', name: 'J' },
      { href: '/download/bdBFPK', name: 'K' },
    ],
    counterBlurb: '',
    counters: [],
  },
  {
    id: 'BtB',
    name: 'Beyond the Beachhead',
    blurb: 'Beyond the Beachhead 2 offers players the opportunity to do cardboard battle in the bocage country.',
    coverF: btbCoverF,
    coverB: '',
    available: false,
    version: '2',
    price1: 0,
    price2: 0,
    boards: [],
    vaslBoards: [
      { href: '/download/bdBFPC', name: 'C' },
      { href: '/download/bdBFPD', name: 'D' },
      { href: '/download/bdBFPE', name: 'E' },
      { href: '/download/bdBFPF', name: 'F' },
    ],
    counterBlurb: '',
    counters: [],
  },
  {
    id: 'HoW',
    name: 'Hell on Wheels',
    blurb: 'The Hell on Wheels Battle Pack concentrates on the U.S. 2nd Armored Division in its battles in Sicily, Normandy, the Siegfried Line, the Battle of the Bulge, and the Rhine campaign.',
    coverF: howCoverF,
    coverB: '',
    available: false,
    version: '',
    price1: 0,
    price2: 0,
    boards: [],
    vaslBoards: [
      { href: '/download/bdBFP1.zip', name: 'Deluxe BFP 1' },
      { href: '/download/bdBFP2.zip', name: 'Deluxe BFP 2 (from RBF4)' },
    ],
    counterBlurb: '',
    counters: [],
  },
];

export default products;
