import ctr21 from '../download/ctr21.png';
import { Button, ButtonGroup, Card, CardGroup, Col, Container, Image, OverlayTrigger, Popover, Row, Tab, Tabs } from 'react-bootstrap';
import products from '../data/bfpProducts.js';
import VaslBoards from '../components/vaslBoards.js';

const Download = () => {
  return (
    <Container fluid className="mt-2">
      <Tabs defaultActiveKey="boards" id="uncontrolled-tab-example" className="mt-2">
        <Tab eventKey="boards" title="VASL Boards">
          <Row>
            {products.map((p) => {
              if (p.vaslBoards.length === 0) return null;
              return (
                <VaslBoards product={p} />
              )
            })}
          </Row>
        </Tab>

        <Tab eventKey="overlays" title="VASL Overlays">
          <Row>
            <Col>
              <CardGroup>
                <Card.Body>
                  <Card.Text>
                    <ButtonGroup vertical>
                      <Button variant="outline-secondary" href="/download/ovrBFPRC">Ruined City</Button>
                      <Button variant="outline-secondary" href="/download/ovrBFPF">Factory (all 6 rotations)</Button>
                      <Button variant="outline-secondary" href="/download/ovrBFPD">Debris (single-hex)</Button>
                      <Button variant="outline-secondary" href="/download/ovrBFPHI">H-1 Hill</Button>
                      <Button variant="outline-secondary" href="/download/ovrBFPHII">H-2 Hill</Button>
                    </ButtonGroup>
                  </Card.Text>
                </Card.Body>
                <Card.Body>
                  <Card.Text>
                    <ButtonGroup vertical>
                      <Button variant="outline-secondary" href="/download/ovrBFPO">O-6a Orchard Village</Button>
                      <Button variant="outline-secondary" href="/download/ovrBFPVI">V-1 Bocage Village</Button>
                      <Button variant="outline-secondary" href="/download/ovrBFPVII">V-2 Bocage Village</Button>
                      <Button variant="outline-secondary" href="/download/ovrBFPVIII">V-3 Bocage Village</Button>
                      <Button variant="outline-secondary" href="/download/ovrBFPCI">C-1 Castle</Button>
                    </ButtonGroup>
                  </Card.Text>
                </Card.Body>
              </CardGroup>
              <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                <Button variant="primary">How to use the overlays in VASL.</Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="files" title="Other Files">
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Castle overlay - Enlarged Size</Card.Title>
                  <Card.Text>
                    <h6>(thanks to Rick Reinesch)</h6>
                    <p><a href="/download/BFP_C-1_Castle.pdf">Download the oversize image for use in <i>High Ground 2</i> scenarios.</a></p>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>BFP VASL Corregidor Extension</Card.Title>
                  <Card.Text>
                    <h6>(thanks to Allan Cannamore)</h6>
                    <p><a href="/download/BFP-CtR_v1.vmdx">Download the CtR VASL extension</a> (v1.0, as of 02/04/2019) for all of the BFP counters for <i>Corregidor: the Rock</i>.</p>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>BFP VASL Counter Extension</Card.Title>
                  <Card.Text>
                    <h6>(thanks to Jeffrey Malter & Tom Repetti for v2, and to Zoltan Grose and Kenneth Knott for v4)</h6>
                    <p><a href="/download/BFP_v403.vmdx">Download the VASL extension</a> (v4.03, as of 10/28/2016) for all of the BFP counters for <i>Operation Cobra</i>, <i>Blood and Jungle</i>, <i>Crucible of Steel</i>, and <i>Poland in Flames</i>.</p>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Corregidor: the Rock</Card.Title>
                  <Card.Text>
                    <p>Scenario CtR-21 <a href="/download/CtR21.pdf" target="_blank">(Download a PDF of overlayed board BFPI)</a>.</p>
                    <Image src={ctr21} thumbnail />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>BFP Plano Labels</Card.Title>
                  <Card.Text>
                    <h6>(thanks to Rick Reinesch)</h6>
                    <p><a href="/download/bfp_asl_plano_labels.zip">Download a spreadsheet</a> for storage of all the BFP counters for <i>Operation Cobra</i>, <i>Blood and Jungle</i>, and <i>Crucible of Steel</i>.</p>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>BFP Armory (Chapter N and more)</Card.Title>
                  <Card.Text>
                    <h6>(thanks to Mark Sockwell)</h6>
                    <p><a href="/download/BFP Armory v4.xlsx">Download the Excel spreadsheet</a> (v4.0, as of 07/13/2020) that details all of the scenarios, maps, overlays, and counters.</p>
                    <p><a href="/download/BFP Armory v4.pdf">Download the PDF Version</a>.</p>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Errata File for Hell On Wheels</Card.Title>
                  <Card.Text>
                    <h6>(thanks to Rick Reinesch)</h6>
                    <p><a href="/download/howErrata.zip">Download the errata</a> for Hell on Wheels.</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default Download;

const popover = (
  <Popover id="popover-basic" className="widePopover">
    <Popover.Header as="h3">Instructions for ITR overlay placement</Popover.Header>
    <Popover.Body>
      <p>Drop these into your VASL/boards/overlays folder and VASL should recognize them.</p>
      <p>Here's how to enter the overlay info into VASL's "Add Overlays" dialog (case does not matter):</p>
      <ul>
        <li><img src="fact.jpg" alt="" />need to enter both hex1 and hex2 for the factory</li>
        <li><img src="debris.jpg" alt="" />only need to enter one hex for the debris overlay</li>
        <li><img src="rc.jpg" alt="" />only need to enter one hex for the rubbled city because its hexgrid is set to start at I1</li>
      </ul>

      <h3>Instructions for overlay placement</h3>

      <table style={{margin: '20px 0'}}>
        <thead>
          <tr>
            <td width="120">Name</td>
            <td width="120">VASL Name</td>
            <td width="120">hex1-hex2</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>BFP-06a</td>
            <td>bfpo6</td>
            <td>i2-none</td>
          </tr>
          <tr>
            <td>BFPH1</td>
            <td>bfphi</td>
            <td>k1-none</td>
          </tr>
          <tr>
            <td>BFPH2</td>
            <td>bfphii</td>
            <td>(as per SSR)</td>
          </tr>
          <tr>
            <td>BFPV1</td>
            <td>bfpvi</td>
            <td>k1-none</td>
          </tr>
          <tr>
            <td>BFPV2</td>
            <td>bfpvii</td>
            <td>(as per SSR)</td>
          </tr>
          <tr>
            <td>BFPV3</td>
            <td>bfpviii</td>
            <td>k1-none</td>
          </tr>
        </tbody>
      </table>

      <p>For BFP-06a, the VASL Name to enter into the "Add Overlay" dialog box is beta-foxtrot-papa-OSCAR-6 (bfpo6, all lower case) NOT the number ZERO-SIX!!!  And yes, you only specify one hex for this overlay now - unlike a previous version where you had to specify i2-j2.</p>

      <p>To use BFPC-1, the entry for the Add Overlay dialog is: bfpci [bdnum], hex1 = i1, hex2 = blank (no need to enter anything).</p>
    </Popover.Body>
  </Popover>
);
