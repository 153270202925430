import { Card, Col, Image } from 'react-bootstrap';
import React from 'react';

class Boards extends React.Component {
  render() {
    const product = this.props.product;
    return (    
      <Col className="mt-2" md={4} sm={6} key={product.id}>
        <Card style={{ border: 'none' }}>
          <Card.Body className="p-0">
            <Card.Title>{product.name}</Card.Title>
            <Card.Text>
              {product.boards.map((b) => {
                return (
                  <Card className="mb-3">
                    <Card.Title className="mb-0">
                      <p className="px-2 rounded text-light bg-success mx-2 my-1 float-end">${b.price}</p>
                      <p className="fw-bold mx-2 my-1">{b.name}</p>
                    </Card.Title>
                    <Image src={b.src} className="mb-0" thumbnail alt={b.name} />
                  </Card>
                )
              })}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    )
  }
}

export default Boards;
