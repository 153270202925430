import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';

const Support = () => {
  return (
    <Container fluid>
      <Tabs defaultActiveKey="rules" id="uncontrolled-tab-example" className="mt-2">
        <Tab eventKey="rules" title="Bounding Fire Rules!">
          <Row>
            <p className="mt-3">Need the Historical Battle Rules or Charts from one of our products in order to play a scenario? You can download a PDF file below.</p>
            <p>Note: These files are the same as the printed material from each product. Any Q&A or Errata have not been incorporated into these files.</p>
            <Col md={4}>
              <h4>Into the Rubble 2</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ITR_Chapter_B v2.pdf" target="_blank">v2 Rules</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ITR_Chapter_B v1.pdf" target="_blank">v1 Rules</Button>
            </Col>
            <Col md={4}>
              <h4>BtB 2</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/BtB2 Rules.pdf" target="_blank">v2 Rules</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/BTB2_Chapter_B.pdf" target="_blank">v1 Rules</Button>
            </Col>
            <Col md={4}>
              <h4>Operation Cobra</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/BFP2_aircraft.pdf" target="_blank">Aircraft</Button>
            </Col>
            <Col md={4}>
              <h4>High Ground 2</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/HG2 Rules.pdf" target="_blank">v2 Rules</Button>
            </Col>
            <Col md={4}>
              <h4>Blood & Jungle</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/capabilities.pdf" target="_blank">Charts</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/B&J Rules.pdf" target="_blank">Terrain Rules</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/BJ_Chapter_G.pdf" target="_blank">Paratrooper Rules</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/BFP3_aircraft.pdf" target="_blank">Aircraft</Button>
            </Col>
            <Col md={4}>
              <h4>Crucible of Steel</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/CoS v2 Rules.pdf" target="_blank">Rules</Button>
            </Col>
            <Col md={4}>
              <h4>Poland in Flames</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/PiF Rules.pdf" target="_blank">Rules</Button>
            </Col>
            <Col md={4}>
              <h4>Objective: Schmidt</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/OS HBR.pdf" target="_blank">Rules</Button>
            </Col>
            <Col md={4}>
              <h4>Onslaught to Orsha</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/OtO Aircraft.pdf" target="_blank">Aircraft</Button>
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <h3 class="german mt-3">Page Numbering Summary</h3>
              <ul class="list-unstyled list-inline">
                <li><b>BFP 1-9</b> Terrain/Module Rules\n(latest version in ITR2, B&J v2, CoS v2)</li>
                <li><b>BFP 4-5</b> Aircraft (OC)</li>
                <li><b>BFP 6-10</b> Aircraft (B&J)</li>
                <li><b>BFP 11-16</b> Aircraft (CoS)</li>
                <li><b>BFP 12-15</b> Aircraft (PiF)</li>
                <li><b>BFP 23-28</b> Aircraft (OtO)</li>
              </ul>
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="errata" title="Q&A and Errata">
          <p className="mt-3">As much as we hate to admit it, mistakes do happen. Follow the links below to download PDF files for the errata from our products. The first button in each section is for a plain format, and the Sticky buttons are for label sheets in your preferred size, US or A4.</p>
          <Row>
            <Col md={4}>
              <h4>Onslaught to Orsha</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Onslaught to Orsha 2 Errata.pdf" target="_blank">Errata</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Onslaught to Orsha 2 Errata-A4.pdf" target="_blank">Errata A4</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Onslaught to Orsha 2 Sticky Errata.pdf" target="_blank">Sticky</Button>
            </Col>
            <Col md={4}>
              <h4>Corregidor: the Rock</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Corregidor Errata.pdf" target="_blank">Errata</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Corregidor Errata-A4.pdf" target="_blank">Errata A4</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Corregidor Sticky Errata.pdf" target="_blank">Sticky</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Corregidor Sticky Errata-A4.pdf" target="_blank">Sticky A4</Button>
              <br class="divide" />
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Corregidor Bombing EoP.pdf" target="_blank">Bombing Example of Play</Button>
            </Col>
            <Col md={4}>
              <h4>Objective: Schmidt</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Objective Schmidt Errata.pdf" target="_blank">Errata</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Objective Schmidt Sticky Errata.pdf" target="_blank">Sticky</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Objective Schmidt Sticky Errata-A4.pdf" target="_blank">Sticky A4</Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <h4>BtB 2 & Op. Cobra</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Normandy Errata.pdf" target="_blank">Errata</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Normandy Sticky Errata.pdf" target="_blank">Sticky</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Normandy Sticky Errata-A4.pdf" target="_blank">Sticky A4</Button>
            </Col>
            <Col md={4}>
              <h4>High Ground 2</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/HG2 Errata.pdf" target="_blank">Errata</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/HG2 Sticky Errata.pdf" target="_blank">Sticky</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/HG2 Sticky Errata A4.pdf" target="_blank">Sticky A4</Button>
            </Col>
            <Col md={4}>
              <h4>Blood and Jungle</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Blood and Jungle Errata.pdf" target="_blank">Errata</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Blood and Jungle Sticky Errata.pdf" target="_blank">Sticky</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Blood and Jungle Sticky Errata-A4.pdf" target="_blank">Sticky A4</Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <h4>Crucible of Steel</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Crucible of Steel Errata.pdf" target="_blank">Errata</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Crucible of Steel Sticky Errata.pdf" target="_blank">Sticky</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Crucible of Steel Sticky Errata-A4.pdf" target="_blank">Sticky A4</Button>
            </Col>
            <Col md={4}>
              <h4>Poland in Flames</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Poland in Flames Errata.pdf" target="_blank">Errata</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Poland in Flames Sticky Errata.pdf" target="_blank">Sticky</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Poland in Flames Sticky Errata-A4.pdf" target="_blank">Sticky A4</Button>
            </Col>
            <Col md={4}>
              <h4>Into the Rubble 2</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ITR2 Errata.pdf" target="_blank">Errata</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ITR2 Sticky Errata.pdf" target="_blank">Sticky</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ITR2 Sticky Errata-A4.pdf" target="_blank">Sticky A4</Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <h4>Into the Rubble</h4>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Into The Rubble Errata.pdf" target="_blank">Errata</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Into The Rubble Sticky Errata.pdf" target="_blank">Sticky</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/Into The Rubble Sticky Errata-A4.pdf" target="_blank">Sticky A4</Button>
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="vasl" title="VASL">
          <Row className="mt-3">
            <Col md={6}>
              <h3>Purchase Record</h3>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/CtR Purchase Record.zip">CtR Purchase Record spreadsheet</Button>
              <h3>VASL Overlays</h3>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ovrBFPRC">RC-1 Ruined City</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ovrBFPF">F-1 Factory (all 6 rotations)</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ovrBFPD">Debris (single-hex)</Button>
              <br class="divide" />
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ovrBFPVI">V-1 Bocage Village</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ovrBFPVII">V-2 Bocage Village</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ovrBFPVIII">V-3 Bocage Village</Button>
              <br class="divide" />
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ovrBFPHI">H-1 Hill</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ovrBFPHII">H-2 Hill</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ovrBFPO">O-6a Orchard Village</Button>
              <Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/ovrR">ITR2 R1-R7 Rubble</Button>

              <Button variant="success" className="me-1 mb-1" data-toggle="modal" href="download/instruction.htm" data-target="#modal">Click to see Directions for using the Overlays in VASL</Button>
            </Col>
            <Col md={6}>
              <h3>VASL Extensions</h3>
              <p><Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/BFPv5.00.vmdx">BFP_v5.00.mdx</Button> Counters for BFP modules: Operation Cobra, Blood and Jungle, Crucible of Steel, Poland in Flames, Objective:Schmidt, Corregidor: the Rock, and Onslaught to Orsha. (Version 5.0)</p>
              <p><Button variant="success" className="me-1 mb-1" href="http://www.boundingfire.com/download/BFP-CtR_v1.vmdx">BFP-CtR_v1.vmdx</Button> Counters for <i>Corregidor: the Rock</i>.</p>
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="contact" title="Contact Us">
          <Row>
            <Col>
              <h4 className="mt-3">Mailing Address</h4>
              <p><b>Bounding Fire Productions</b>
                <br/>7907 Tisdale Dr
                <br/>Austin, TX 78757
              </p>
              <Button href="mailto:support@boundingfire.com">Click here to Email Support</Button>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default Support;