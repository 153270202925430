import ffsCoverF from '../images/covers/ffs.jpg';
import ftc10CoverF from '../images/covers/ftc10.jpg';
import ftc8CoverF from '../images/covers/ftc8.png';
import ghiCoverF from '../images/covers/green-hell.jpg';
import lft14CoverF from '../images/covers/lft14.jpg';
import ne2CoverF from '../images/covers/NorPack2.jpg';
import rpc3CoverF from '../images/covers/rpc3.jpg';

const products = [
  {
    id: '',
    name: 'The Green Hell of Inor',
    blurb: 'The Green Hell of Inor is LFT\'s attempt at bringing you 2 weeks of epic fighting that took place in the Ardennes Forest during the very first days of Fall Gelb, the invasion of France.',
    coverF: ghiCoverF,
    coverB: '',
    version: '2',
    price1: 140,
    price2: 160,
    boards: [],
  },
  {
    id: '',
    name: 'From the Cellar 10',
    blurb: 'FTC10 is a selection of 10 scenarios of "Sand & Snow", a themed HASL issue which examines two critical battles that the United States Marine Corps fought in WW2 and in Korea. The two battles (Tarawa and Toktong Pass) could not be more different in geography, weather and enemy capabilities.',
    coverF: ftc10CoverF,
    coverB: '',
    version: '',
    price1: 40,
    price2: 70,
    boards: [],
  },
  {
    id: '',
    name: 'Nor\'easter Pack II',
    blurb: 'This is the 2021 product from the new England ASLERs! This is from the same producers who brought you the YASL pack a few years ago. This time they had LFT produce the pack. It features 3 boards and 15 scenarios.',
    coverF: ne2CoverF,
    coverB: '',
    version: '',
    price1: 0,
    price2: 0,
    boards: [],
  },
  {
    id: '',
    name: 'Fight for Seoul',
    blurb: 'Fight for Seoul captures the intensity of the battles that led to the liberation of Seoul by U.S. Marines and ROK Marine Corps from KPA forces. Never-seen-before terrain combinations will raise new and challenging issues to the field commanders of your ASL army. How well will you fight for Seoul?',
    coverF: ffsCoverF,
    coverB: '',
    version: '',
    price1: 121.50,
    price2: 150,
    boards: [],
  },
  {
    id: '',
    name: 'LFT 14',
    blurb: 'LFT 14 focuses on the Italians, with 32 scenarios, 2 counter sheets, and a 104 page LFT magazine. Counters in LFT include Aircraft, more Italian units (MMC/SMC/SW) plus all the counters needed in order to play the scenarios provided within this issue. Some of the SW are captured SW in Italian color for a better fog of war.',
    coverF: lft14CoverF,
    coverB: '',
    version: '',
    price1: 80,
    price2: 105,
    boards: [],
  },
  {
    id: '',
    name: 'From the Cellar 8',
    blurb: 'This product contains both a 56 pages magazine and 15 scenarios. The magazine itself contains historical and technical articles on subjects you never dared (or even imagined?) asking.',
    coverF: ftc8CoverF,
    coverB: '',
    version: '',
    price1: 50,
    price2: 0,
    boards: [],
  },
  {
    id: '',
    name: 'Rat Pocket Charts',
    blurb: 'The latest Rat Pocket Charts have been updated and expanded to include the Korean War information!',
    coverF: rpc3CoverF,
    coverB: '',
    version: '3',
    price1: 44,
    price2: 0,
    boards: [],
  },
];

export default products;
