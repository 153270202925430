import { Button, ButtonGroup, Card, Col } from 'react-bootstrap';
import React from 'react';

class VaslBoards extends React.Component {
  render() {
    const product = this.props.product;
    return (    
      <Col className="mt-2" md={3} sm={6} key={product.id}>
        <Card style={{ border: 'none' }}>
          <Card.Body>
            <Card.Title>{product.name}</Card.Title>
            <Card.Text>
              <ButtonGroup vertical>
              {product.vaslBoards.map((b) => {
                const vari = b.name.includes('DW') ? 'outline-secondary' : 'outline-success';
                const name = b.name.includes('Map') ? b.name : `Board ${b.name}`;
                return (
                  <Button variant={vari} href={b.href}>{name}</Button>
                )
              })}
              </ButtonGroup>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    )
  }
}

export default VaslBoards;
