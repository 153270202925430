import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { Elements, CheckoutForm } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import Navigation from './components/navigation';
import React from "react";

import Bfp from './pages/bfp';
import Download from './pages/download';
import Lft from './pages/lft';
import Parts from './pages/parts';
import Support from './pages/support';

// const stripePromise = loadStripe('pk_test_51Jn8dLAl3APlV3C9ZcPd9xstBo1oDhZlFfPinPeZPDG9AW55XBfCOVLnEqF6nzGNI4pSi25X14w5pwfos90ukmxC00AkeFLIFH');

export default function Website() {
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: '{{CLIENT_SECRET}}',
  // };

  return (
    <Router>
      <div>
        <Navigation />
        <Switch>
          <Route exact path="/">
            <Bfp />
          </Route>
          <Route path="/lft">
            <Lft />
          </Route>
          <Route path="/support">
            <Support />
          </Route>
          <Route path="/parts">
            <Parts />
          </Route>
          <Route path="/download">
            <Download />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

      // <Elements stripe={stripePromise} options={options}>
      //   <CheckoutForm />
      // </Elements>
