import { Card, Col, Image } from 'react-bootstrap';
import React from 'react';

class Product extends React.Component {
  render() {
    const product = this.props.product;
    return (    
      <Col lg={3} md={4} sm={6} key={product.id}>
        <Card>
          <Card.Body>
            <Image src={product.coverF} thumbnail width="100%" />
            <Card.Title>{product.name}</Card.Title>
            <Card.Text>
            {product.blurb}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    )
  }
}

export default Product;
