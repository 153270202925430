import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import products from '../data/bfpProducts.js';
import Boards from '../components/boards.js';
import Counters from '../components/counters.js';

const Parts = () => {
  return (
    <Container fluid className="mt-3">
      <h5 className="float-end" style={{ 'color': 'red' }}>Don't forget to add shipping if you are just buying boards!</h5>
      <h2>Bounding Fire Parts!</h2>
      <Tabs defaultActiveKey="boards" id="uncontrolled-tab-example" className="mt-2">
        <Tab eventKey="boards" title="Boards">
          <Row>
            <Col md={6}>
              <p className="m-2">Shipping is free if ordered with a BFP/LFT product.</p>
            </Col>
            <Col md={6}>
              <p className="m-2">If ordering just boards, then add shipping based on your location.</p>
              <table className="m-3">
                <tr>
                  <td>
                    <select name="os0">
                      <option value="US">US $10.00 USD</option>
                      <option value="Canada">Canada $25.00 USD</option>
                      <option value="International">International $35.00 USD</option>
                    </select>
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
          <Row>
            {products.map((p) => {
              if (p.boards.length === 0) return null;
              return (
                <Boards product={p} />
              )
            })}
          </Row>
        </Tab>
        <Tab eventKey="counters" title="Counters">
            {products.map((p) => {
              if (p.counters.length === 0) return null;
              return (
                <Counters product={p} />
              )
            })}
        </Tab>
      </Tabs>
    </Container>
  );
}

export default Parts;
