import { Button, Card, Col } from 'react-bootstrap';
import React from 'react';

class Counters extends React.Component {
  render() {
    const product = this.props.product;
    return (    
      <Col className="mt-2" md={4} sm={6} key={product.id}>
        <Card>
          <Card.Body className="p-2">
            <Card.Title>{product.name}</Card.Title>
            <Card.Text>
              <p className="fw-bold px-2">{product.counterBlurb}</p>
              <div className="d-grid gap-2">
                {product.counters.map((ctr) => {
                  return (
                    <Button variant="outline-success">
                      <p className="px-2 rounded text-light bg-success mx-2 my-1 float-end">${ctr.price}</p>
                      <p className="fw-bold mx-2 my-1">{ctr.name}</p>
                    </Button>
                  )
                })}
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    )
  }
}

export default Counters;
