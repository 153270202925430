import { Container, Row } from 'react-bootstrap';
import products from '../data/bfpProducts.js';
import Product from '../components/Product.js';

const Bfp = () => {
  return (
    <Container fluid className="mt-2">
      <h2>Bounding Fire Products</h2>
      <Row>
      {products.map((p) => {
        return (
          <Product product={p} />
        )
      })}
      </Row>
    </Container>
  );
}

export default Bfp;
